



































































import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import api from "@/api";
import { UserDto } from "../../api/appService";
import PagedTableViewSimple from "@/components/PagedTableViewSimple/index.vue";
@Component({
  components: { PagedTableViewSimple },
})
export default class UserSelect extends Vue {
  @Prop({ required: false, default: false }) single!: boolean;
  public show = false;

  queryForm: any = { keyword: undefined };

  selection: UserDto[] = [];

  async handleSelectionChange(e: any) {
    console.log(e);
    this.selection = e;
  }

  fetchData(params: any) {
    return api.user.getAll(params);
  }

  async handleSearch() {
    (this.$refs.pagedTableOfUser as any).refreshData();
  }

  async select(user: UserDto) {
    this.$emit("select", user);
    this.show = false;
  }

  async handleSave_users() {
    // console.log(this.selection);
    this.$emit("select", this.selection);
    this.show = false;
  }
}
